<template>
    <div>

        <ComponentDialog v-if="showComponentDialog" v-bind="componentDialogParameters"
                         v-on:hidden="showComponentDialog = false"
                         v-on:select-component="selectComponent"
                         v-on:zoom-component="zoomComponent"
                         v-on:zoom-reset="zoomReset"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="width: 100%;">
                    <tr>
                        <td style="padding: 0 0 0 1rem; width: 280px;">
                            <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                            {{ $i18n.tc('translations.' + name, 2).toUpperCase() }}
                        </td>
                        <td style="width: 1rem;"></td>
                        <template v-if="serverDataLoaded">
                            <template v-if="linkedToPhase">
                                <td style="padding: 0 10px; border: solid 1px lightgray; width: 30%;">
                                    {{ linkedToolData.phase.label }}
                                </td>
                                <td style="width: 1rem;"/>
                                <td style="padding: 0 10px; border: solid 1px lightgray;">
                                    <LinkAction :action="actions.workPlanLinkAction"/>
                                </td>
                                <td style="padding: 0; width: 90px; text-align: right;">
                                    (#{{ linkedToolData.phase.id }})
                                </td>
                            </template>
                            <template v-else>
                                <td style="padding: 0 10px; border: solid 1px lightgray;">
                                    <router-link :to="{name: 'work_plan_list'}" class="st-cardheader-link">
                                        {{ $i18n.t('translations.SketchUp model is not linked to a workplan phase') }}!
                                    </router-link>
                                </td>
                            </template>
                        </template>
                        <td style="width: 1rem;"></td>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="serverDataLoaded">
                <Table :key="'component-table-'+reloadTableComponent" :reload-table="reloadTable"
                       header-class="light" header-style="font-weight: normal;" footer-class="light" body-class=""
                       with-filter with-pagination pagination-always-visible no-state-loading autofocus
                       :lines-per-page=35 th-actions-style="width:160px;" :rows="filteredComponentList"
                       :fields="fields.table" :actions="actions.table" :parameter-data="tableParameterData" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:detail-components="detailComponents"
                       v-on:inline-edit="toggleComponentCheck"
                       v-on:make-unique="makeComponentsUnique"
                       v-on:refresh="getComponentInstances"
                       v-on:renumber-definitions="renumberDefinitions"
                       v-on:reset-ignore-flipped="resetIgnoreFlipped"
                       v-on:select-all="selectAll"
                       v-on:select-components="selectComponent"
                       v-on:select-filter-options="selectFilterOptions"
                       v-on:set-ignore-flipped="setIgnoreFlipped"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>
            </div>

        </div>
    </div>
</template>

<script>
import ComponentDialog from "@/components/dialogs/sketchup/ComponentDialog.vue";
import LinkAction from "@/components/actions/LinkAction.vue";
import SketchUp from "@/mixins/SketchUp";
import Table from "@/components/Table.vue";

/* global sketchup:false */

export default {
    name: 'Components',
    components: {
        ComponentDialog,
        LinkAction,
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    mixins: [SketchUp],
    data() {
        return {
            sketchUpToolData: null,
            linkedToolData: null,
            serverDataLoaded: false,
            linkedToPhase: false,
            fields: null,
            actions: null,
            products: null,
            settings: null,
            rawComponentList: [],
            processedComponentList: [],
            filteredComponentList: [],
            checkedComponentList: [],
            assemblyData: [],
            reloadTableComponent: 0,
            reloadTable: 0,
            tableParameterData: null,
            definitionNameToRenumber: null,
            filterOptions: {
                productsOnly: false,
                assemblyOnly: false,
                nonuniqueOnly: false,
                uncheckedOnly: false,
            },
            showComponentDialog: false,
            componentDialogParameters: {
                title: null,
                actions: null,
                fields: null,
                rows: null,
            },
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        clearSelection() {
            sketchup.clearSelection();
        },
        detailComponents(component) {
            let rows = [];
            component.instances.forEach(entityID => {
                let instanceIdx = rows.findIndex(row => row.entity_id === entityID);
                if (instanceIdx === -1) {
                    rows.push({
                        id: rows.length + 1,
                        component_color: component.component_color,
                        entity_id: entityID,
                        instances: 1,
                        flipped: component.flipped,
                        flipped_style: component.flipped_style,
                    });
                } else {
                    rows[instanceIdx].instances++;
                }
            });
            this.componentDialogParameters.title = component.definition;
            this.componentDialogParameters.actions = this.actions.detail;
            this.componentDialogParameters.fields = this.fields.detail;
            this.componentDialogParameters.rows = rows;
            this.showComponentDialog = true;
        },
        fetchData() {
            this.$http.get(this.api + '/manage_components/' + this.sketchUpToolData.phase_id, {}).then((res) => {
                this.fields = res.data.fields;
                this.actions = res.data.actions;
                this.linkedToolData = res.data.tool_data;
                this.linkedToPhase = !!(this.linkedToolData.phase);
                this.products = res.data.products;
                this.assemblyData = res.data.assembly_data ? res.data.assembly_data : [];
                this.settings = res.data.settings;
                this.serverDataLoaded = true;
                sketchup.getComponentInstances();
            }).catch((error) => {
                console.log("Components:fetchData():error:", error);
            });
        },
        filterComponentList() {
            this.filteredComponentList = this.processedComponentList.filter(component =>
                (!this.filterOptions.nonuniqueOnly || component.hide_make_unique === false) &&
                (!this.filterOptions.productsOnly || component.attribute_naam !== null) &&
                (!this.filterOptions.assemblyOnly || this.settings.assembly_definitions.includes(component.definition.substring(0, component.definition.lastIndexOf("#")))) &&
                (!this.filterOptions.uncheckedOnly || component.checked === 0)
            );

            let definitionList = [];
            let defaultDefinition = '<' + this.$i18n.tc("translations.Assembly Component", 2) + '>';
            this.filteredComponentList.forEach(component => {
                if (component.definition) {
                    let d = component.definition
                    let idx = d.lastIndexOf('#');
                    if (idx > 0) {
                        d = d.substring(0, idx);
                    }
                    if (!definitionList.includes(d)) {
                        definitionList.push(d);
                    }
                }
            });
            definitionList.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            definitionList.unshift(defaultDefinition);
            this.tableParameterData = {
                definition_options: definitionList,
            };

            this.reloadTable++;
        },
        getComponentInstances() {
            this.state.loading = true;
            sketchup.getComponentInstances();
        },
        getComponentInstancesCallback(entities) {
            this.rawComponentList = JSON.parse(entities);
            this.processComponentList();
        },
        getModelDefinitionsCallback(definitions) {
            let defaultDefinition = '<' + this.$i18n.tc("translations.Assembly Component", 2) + '>';
            let definitionsToRenumber;
            if (this.definitionNameToRenumber === defaultDefinition) {
                definitionsToRenumber = this.getDefinitionsToRenumber(definitions, this.settings.assembly_definitions);
            } else {
                definitionsToRenumber = this.getDefinitionsToRenumber(definitions, [this.definitionNameToRenumber]);
            }
            sketchup.renumberDefinitions(definitionsToRenumber);
        },
        getToolDataCallback(sketchUpToolData) {
            this.sketchUpToolData = sketchUpToolData;
            this.fetchData();
        },
        makeComponentsUnique(component) {
            this.state.loading = true;
            sketchup.makeComponentsUnique(component.entity_ids);
        },
        makeComponentsUniqueCallback() {
            sketchup.getComponentInstances();
        },
        processComponentList() {
            this.$worker.run((rawComponentList, linkedToPhase, assemblyDefinitions, assemblyData, products, checkedComponentList) => {
                let newProcessedComponentList = [];

                rawComponentList.forEach(component => {
                    let phaseComponent = assemblyData.find(assemblyComponent => {
                        return assemblyComponent.definition === component.definition
                    });

                    let i = newProcessedComponentList.findIndex(item =>
                        (item.definition === component.definition) &&
                        (item.attribute_naam === component.attribute_naam) &&
                        ((phaseComponent && phaseComponent.ignore_flipped === 1) || (item.is_flipped === component.is_flipped))
                    );
                    if (i === -1) {
                        let j = newProcessedComponentList.findIndex(item =>
                            (item.definition === component.definition) &&
                            (item.attribute_naam === component.attribute_naam) &&
                            (item.is_flipped === (component.is_flipped === 1 ? 0 : 1))
                        );
                        let newComponent = {
                            id: newProcessedComponentList.length + 1,
                            entity_ids: [component.entity_id],
                            instances: [component.entity_id],
                            definition: component.definition ? component.definition : '',
                            instances_in_model: component.instances_in_model,
                            component_color: {
                                value: component.component_color,
                                label: component.component_color_name,
                            },
                            attribute_naam: component.attribute_naam,
                            attribute_hoeveelheid: component.attribute_hoeveelheid,
                            attribute_eenheid: component.attribute_eenheid,
                            is_flipped: (phaseComponent && phaseComponent.ignore_flipped === 1) ? 0 : component.is_flipped,
                            known_in_tool: (products.indexOf(component.attribute_naam) > -1 ? 'X' : ''),
                            components_in_selection: 1,
                            instances_in_selection: 1,
                            amount: (component.attribute_hoeveelheid > 0 ? component.attribute_hoeveelheid : ''),
                        }

                        // flipped
                        if (phaseComponent && phaseComponent.ignore_flipped === 1) {
                            newComponent.flipped = '!';
                            newComponent.flipped_style = "background: orange; color: white;";
                        } else if (newComponent.is_flipped === 1) {
                            newComponent.flipped = 'X';
                        } else {
                            newComponent.flipped = '';
                        }

                        // set ignore_flipped
                        newComponent.ignore_flipped = (phaseComponent && phaseComponent.ignore_flipped === 1);

                        // set assembly definition
                        newComponent.assembly_definition = assemblyDefinitions.includes(newComponent.definition.substring(0, newComponent.definition.lastIndexOf("#")));

                        // set checked status
                        let checkedIdx = checkedComponentList.findIndex(item => (
                            (item.definition === newComponent.definition) &&
                            (item.is_flipped === newComponent.is_flipped))
                        );
                        if (checkedIdx === -1) {
                            newComponent.checked = 0;
                        } else {
                            newComponent.checked = 1;
                            newComponent.checked_style = 'background: #28a745; color: white;';
                        }

                        // set hide_make_unique && set_ignore_flipped
                        if (j === -1) {
                            newComponent.hide_make_unique = true;
                            newComponent.hide_set_ignore_flipped = true;
                            newComponent.hide_reset_ignore_flipped = !newComponent.ignore_flipped;
                        } else {
                            newProcessedComponentList[j].hide_make_unique = false;
                            newProcessedComponentList[j].hide_set_ignore_flipped = (!linkedToPhase || !newProcessedComponentList[j].assembly_definition);
                            newProcessedComponentList[j].hide_reset_ignore_flipped = true;
                            if (newProcessedComponentList[j].is_flipped === 1) {
                                newProcessedComponentList[j].definition_style = 'background: red; color: white;';
                            }
                            newComponent.hide_make_unique = false;
                            newComponent.hide_set_ignore_flipped = (!linkedToPhase || !newProcessedComponentList[j].assembly_definition);
                            newComponent.hide_reset_ignore_flipped = true;
                            if (newComponent.is_flipped === 1) {
                                newComponent.definition_style = 'background: red; color: white;';
                            }
                        }

                        newProcessedComponentList.push(newComponent);
                    } else {
                        if (newProcessedComponentList[i].entity_ids.indexOf(component.entity_id) === -1) {
                            newProcessedComponentList[i].entity_ids.push(component.entity_id);
                            newProcessedComponentList[i].components_in_selection++;
                        }
                        newProcessedComponentList[i].instances.push(component.entity_id);
                        newProcessedComponentList[i].instances_in_selection++;
                        if (component.attribute_hoeveelheid > 0) {
                            newProcessedComponentList[i].amount += component.attribute_hoeveelheid;
                        }
                    }
                });

                newProcessedComponentList.sort(function (a, b) {
                    let idxA = a.definition.lastIndexOf('#');
                    let idxB = b.definition.lastIndexOf('#');
                    if (idxA > -1 && idxB > -1 && a.definition.substring(0, idxA) === b.definition.substring(0, idxB)) {
                        if (parseInt(a.definition.substring(idxA + 1)) < parseInt(b.definition.substring(idxB + 1))) return -1;
                        if (parseInt(a.definition.substring(idxA + 1)) > parseInt(b.definition.substring(idxB + 1))) return 1;
                    } else {
                        if (a.definition < b.definition) return -1;
                        if (a.definition > b.definition) return 1;
                    }
                    if (a.attribute_naam < b.attribute_naam) return -1;
                    if (a.attribute_naam > b.attribute_naam) return 1;
                    if (a.attribute_hoeveelheid < b.attribute_hoeveelheid) return -1;
                    if (a.attribute_hoeveelheid > b.attribute_hoeveelheid) return 1;
                    if (a.is_flipped < b.is_flipped) return -1;
                    if (a.is_flipped > b.is_flipped) return 1;
                    return 0;
                });

                return newProcessedComponentList;
            }, [this.rawComponentList, this.linkedToPhase, this.settings.assembly_definitions, this.assemblyData, this.products, this.checkedComponentList]).then(data => {
                this.processedComponentList = data;
                this.filterComponentList();
                this.reloadTableComponent++;
                this.state.loading = false;
            }).catch(error => {
                console.log("Components:processComponentList():error:", error);
            });
        },
        renumberDefinitions(data) {
            this.state.loading = true;
            this.definitionNameToRenumber = data.definition;
            sketchup.getModelDefinitions();
        },
        renumberDefinitionsCallback() {
            sketchup.getComponentInstances();
        },
        resetIgnoreFlipped(component) {
            this.state.loading = true;
            let phaseIndex = this.assemblyData.findIndex(assemblyComponent => {
                return assemblyComponent.definition === component.definition;
            });
            if (phaseIndex !== -1) {
                delete this.assemblyData[phaseIndex].ignore_flipped;
                this.$http.put('/managed_orders/update_assembly_data/' + this.linkedToolData.phase.id, {
                    assembly_data: this.assemblyData,
                }, {}).then(() => {
                    this.processComponentList();
                }).catch((error) => {
                    console.log("Components:resetIgnoreFlipped():error:", error);
                });
            }
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(component) {
            if ('entity_id' in component) {
                sketchup.selectComponent(component.entity_id);
            } else {
                sketchup.selectComponents(component.entity_ids);
            }
        },
        selectFilterOptions(choices) {
            choices.forEach(choice => {
                this.filterOptions[choice.name] = choice.value;
            });
            this.filterComponentList();
        },
        setIgnoreFlipped(component) {
            this.state.loading = true;
            let phaseIndex = this.assemblyData.findIndex(assemblyComponent => {
                return assemblyComponent.definition === component.definition;
            });
            if (phaseIndex < 0) {
                this.assemblyData.push({
                    definition: component.definition,
                    ignore_flipped: 1,
                });
            } else {
                this.assemblyData[phaseIndex].ignore_flipped = 1;
            }

            this.$http.put('/managed_orders/update_assembly_data/' + this.linkedToolData.phase.id, {
                assembly_data: this.assemblyData,
            }, {}).then(() => {
                this.processComponentList();
            }).catch((error) => {
                console.log("Components:setIgnoreFlipped():error:", error);
            });
        },
        toggleComponentCheck(component) {
            let i = this.processedComponentList.findIndex(item => (item.id === component.id));
            if (component.checked === 1) {
                this.processedComponentList[i].checked_style = 'background: #28a745; color: white;';
            } else {
                delete this.processedComponentList[i].checked_style;
            }
            this.processedComponentList[i].checked = component.checked;
            i = this.checkedComponentList.findIndex(item => ((item.definition === component.definition) && (item.is_flipped === component.is_flipped)));
            if (i === -1) {
                this.checkedComponentList.push({
                    definition: component.definition,
                    is_flipped: component.is_flipped,
                });
            } else {
                this.checkedComponentList.splice(i, 1)
            }
            this.filterComponentList();
        },
        zoomComponent(component) {
            sketchup.renderingOption('InactiveHidden', true);
            sketchup.renderingOption('InstanceHidden', true);
            sketchup.layer(this.settings.assembly_tag, true);
            if ('entity_id' in component) {
                sketchup.zoomExtents(component.entity_id);
            } else {
                sketchup.zoomExtents(component.entity_ids[0]);
            }
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.layer(this.settings.assembly_tag, false);
            sketchup.zoomExtents();
        },
    }
}
</script>

<style scoped>
</style>